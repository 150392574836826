import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';

type DialogHandlerProps = {
    buttonSelector: string;
    dialogSelector: string;
};

export default class DialogHandler {
    readonly #dialogToggleButtons: HTMLElement[];

    public readonly dialog: Dialog | null;

    public constructor({ buttonSelector, dialogSelector }: DialogHandlerProps) {
        this.#dialogToggleButtons = Array.from(document.querySelectorAll(buttonSelector));
        this.dialog = document.querySelector(dialogSelector);
    }

    public initialize(): void {
        if (!this.#dialogToggleButtons.length || !this.dialog) {
            return;
        }

        for (const button of this.#dialogToggleButtons) {
            button.addEventListener('click', this.#openDialog);
        }
    }

    public destroy(): void {
        if (!this.#dialogToggleButtons.length || !this.dialog) {
            return;
        }

        for (const button of this.#dialogToggleButtons) {
            button.removeEventListener('click', this.#openDialog);
        }
    }

    readonly #openDialog = (): void => {
        this.dialog?.showModal();
    };
}
